<template>
  <div>
    <h1>PaletteTest</h1>
    minDeltaE:
    <b-input debounce="400" v-model="dE" type="number"/>
    colors:
    <b-input debounce="400" v-model="colors" type="number"/>
    minL {{ minL }}:
    <b-input debounce="400" v-model="minL" type="range" min="0" :max="maxL"/>
    maxL {{ maxL }}:
    <b-input debounce="400" v-model="maxL" type="range" :min="minL" max="100"/>
    minA {{ minA }}:
    <b-input debounce="400" v-model="minA" type="range" min="-125" :max="maxA"/>
    maxA {{ maxA }}:
    <b-input debounce="400" v-model="maxA" type="range" :min="minA" max="125"/>
    minB {{ minB }}:
    <b-input debounce="400" v-model="minB" type="range" min="-125" :max="maxB"/>
    maxB {{ maxB }}
    <b-input debounce="400" v-model="maxB" type="range" :min="minB" max="125"/>
    maxIterations:
    <b-input debounce="400" v-model="maxIter" min="1" type="number"/>
    <b-tabs>
      <b-tab title="Palette">
        <b-container fluid>
          <b-row>
            <b-col :key="p" v-for="(p, i) in palette_css"
                   :style="'min-width: 10em; min-height: 10em; background-color:' + p + ';'">
              <p :style="'filter: invert(100%); color: ' + p + ';'">
                {{ i }} ({{ p }})
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
      <b-tab title="Scale">
        <h1>Scales</h1>
        <b-container fluid v-for="(_, outer_row) in Array(this.colors/4)" :key="outer_row+'_out'">
          <b-row :key="i" v-for="(_, i) in Array(11)">
            <b-col :key="p + '_' + i" v-for="p in palette_scales.slice(outer_row*4,(outer_row+1)*4)"
                   :style="'min-width: 25%; min-height: 25%; background-color: ' + lab_to_css([p[0]-i*10, p[1], p[2]]) + ';'">
              <p :style="'filter: invert(100%); color: ' + p + ';'">
                {{ lab_to_css([p[0] - i * 10, p[1], p[2]]) }}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  name: 'PaletteTest',
  methods: {
    deltaE(c_1, c_2) {
      const l = c_1[0] - c_2[0]
      const a = Math.abs(c_1[1]) - Math.abs(c_2[1])
      const b = c_1[2] - c_2[2]
      return Math.sqrt(l * l + a * a + b * b)
    },
    getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    lab_to_css(lab) {
      return `lab(${lab[0]} ${lab[1]} ${lab[2]})`
    }
  },
  data() {
    return {
      dE: 20,
      colors: 100,
      minL: 25,
      maxL: 75,
      minA: -97,
      maxA: 100,
      minB: -100,
      maxB: 100,
      maxIter: 5000,
    }
  },
  computed: {
    palette_raw() {
      const list = []
      for (let i = 0; i < this.colors; i++) {
        const rand_color_lab = [this.getRandomInt(this.minL, this.maxL), this.getRandomInt(this.minA, this.maxA), this.getRandomInt(this.minB, this.maxB)]
        let iter = 0
        while (list.some(c => this.deltaE(c, rand_color_lab) < this.dE)) {
          rand_color_lab[0] = this.getRandomInt(this.minL, this.maxL)
          rand_color_lab[1] = this.getRandomInt(this.minA, this.maxA)
          rand_color_lab[2] = this.getRandomInt(this.minB, this.maxB)
          iter++
          if (iter > this.maxIter) {
            rand_color_lab[0] = 0
            rand_color_lab[1] = 0
            rand_color_lab[2] = 0
            list.push(rand_color_lab)
            break
          }
        }
        list.push(rand_color_lab)
      }
      return list
    },
    palette_scales() {
      return this.palette_raw.map(c => [100, c[1], c[2]])
    },
    palette_css() {
      return this.palette_raw.map(c => this.lab_to_css(c))
    }
    ,
  }
}
</script>
